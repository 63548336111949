import React, { Component } from "react"
import axios from "axios"
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Title } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, Title, LinearScale);

const TOKEN = "Bearer ad22e2689143fdc6906b7b12a0c00e7fcb9a587ab5ecf872b9b586c9a251beeacdc1448cc042cb739ff1ef45806d698eaa582c91b203b272f7734dab605b1bdaa712850579cbadfc2d7c8a407bcfcf85f4e7f2013bc86cbeb17c5c2e3e89370ec1f9870588f40aa915797f56be728707652eec1d04f5bc303719941be0359e9c"


const pieKeys = ["Non irritant", "Très légèrement irritant", "Légèrement irritant", "Modérément irritant", "Potentiellement irritant", "Irritant", "Très irritant"]
const pieKeysEn = ["Non irritant", "Very slightly irritant", "Slightly irritant", "Moderately irritant", "Potentially irritant", "Irritant", "Very irritant"]

const pieColors = ['#4EA4E6', '#84BB94', '#9BE8C6', "#FADD73", '#F0A1CB',  "#FA7477",  "#8B4041"]
const productsResults = ["non_irritant", "very_slightly_irritant", "slightly_irritant", "moderately_irritant", "potentially_irritant", "irritant", "very_irritant"]

const options = {
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
        tooltip: {
            callbacks: {
                label: function (a) {
                    return a.formattedValue + "%"
                }
            }
        },
        legend: {
            position: "bottom",
            labels: {
                color: "white"
            },
        },
    },
}

const barChartOptions = {
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
        tooltip: {
            callbacks: {
                label: function (a) {
                    return a.formattedValue + "%"
                }
            }
        },
        legend: {
            display: false
        },
    },
}


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 36,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 3,
        color: "#E5904F",
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#E5904F',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 18 / 2,
        opacity: 1,
        border: 'solid 1px #E5904F',
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fff9f5',
        boxSizing: 'border-box',
    },
}));

const AntSwitchPie = styled(Switch)(({ theme }) => ({
    width: 36,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 16,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 3,
        color: "#E5904F",
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#E5904F',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 18 / 2,
        opacity: 1,
        border: 'solid 1px #fff9f5',
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fff9f5',
        boxSizing: 'border-box',
    },
}));


const studyType = [
    {
        "FR": "Tolérance cutanée",
        "EN": "Cutaneous tolerance",
        "tests": [
            { "FR": "Patch test", "EN": "Patch Test" },
            { "FR": "PrediSkin (Explants de peau humaine)", "EN": "PrediSkin (Human skin explant)" },
            { "FR": "Épiderme humain reconstruit", "EN": "Reconstructed Human Epidermis" },
        ]
    },
    {
        'FR': "Tolérance des muqueuses",
        "EN": "Mucous membrane tolerance",
        "tests": [
            { "FR": "Épithélium vaginal humain reconstruit", "EN": "Reconstructed Human Vaginal Epithelium" },
            { "FR": "Épithélium gingival humain reconstruit", "EN": "Reconstructed Human Gingival Epithelium" },
        ]
    },
    {
        'FR': "Tolérance oculaire",
        "EN": "Ocular tolerance",
        "tests": [
            { "FR": "Neutre Rouge Libération", "EN": "Neutral Red Release" },
            { "FR": "Het-cam", "EN": "Het-cam" },
            { "FR": "Reconstruction d'un épithélium humain semblable à la cornée", "EN": "Reconstructed Human Cornea-like Epithelium" },
        ]
    }
]

class AppForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            categories_en: [],
            selectedCat: "",
            products: [],
            isDilution: "YN",
            isOcclusif: "YN",
            isValid: false,
            selectedStudyTest: null,
            edited: false,
            isPie: true,
            selectedStudyType: null,
            categoryTitle: {
                FR: "",
                EN: ""
            }
        };
        this.onChangeSelectedCategory = this.onChangeSelectedCategory.bind(this);
        this.onEditForm = this.onEditForm.bind(this);
        this.onValidForm = this.onValidForm.bind(this);
        this.dilutionClicked = this.dilutionClicked.bind(this);
        this.choiceChart = this.choiceChart.bind(this);
        this.occlusifClicked = this.occlusifClicked.bind(this);
        this.changeStudyTest = this.changeStudyTest.bind(this)
        this.changeStudyType = this.changeStudyType.bind(this)
        this.categoryToString = this.categoryToString.bind(this)
    }


    getOptions() {
        const requestUrl = "https://dev-prod-eurosafe.wepredic.com/api/middle-categories?populate=*"
        axios.get(requestUrl, { headers: { 'Authorization': TOKEN } }).then(response => {

            const mainCategories = {}
            const mainCategoriesEN = {}

            response.data.data.forEach(category => {
                const mainCategory = category.attributes.main_category.data.attributes.name
                const mainCategory_en = category.attributes.main_category.data.attributes.name_en
                if (!mainCategories[mainCategory]) {
                    mainCategories[mainCategory] = [category];
                } else {
                    mainCategories[mainCategory].push(category);

                }
                if (!mainCategoriesEN[mainCategory_en]) {
                    mainCategoriesEN[mainCategory_en] = [category];
                } else {
                    mainCategoriesEN[mainCategory_en].push(category);

                }
            });
            this.setState({
                categories: mainCategories,
                categories_en: mainCategoriesEN
            })
        })
    }

    componentDidMount() {
        this.getOptions()
    }


    onChangeSelectedCategory(event) {
        if (event.target.value !== "") {
            let cat = this.categoryToString(event.target.value)
            this.setState({
                selectedCat: event.target.value,
            }, () => this.onEditForm())
        } else {
            this.setState({
                products: [],
                edited: false,
                selectedCat: "",

            })
        }
    }

    onEditForm() {

        const occlusionValue = this.state.isOcclusif === "Y" ? "&filters[$and][3][soo][$eq]=occlusive" : this.state.isOcclusif === "N" ? "&filters[$and][2][soo][$eq]=SO" : ""
        let dilutionValue = ""
        if (this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 0) {
            dilutionValue = this.state.isDilution === "Y" ? "&filters[$and][2][dilution][$ne]=100%" : this.state.isDilution === "N" ? "&filters[$and][1][dilution][$eq]=100%" : ""
        } else if (this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 1) {
            dilutionValue = this.state.isDilution === "Y" ? "&filters[$and][2][concentration][$gte]=100" : this.state.isDilution === "N" ? "&filters[$and][1][concentration][$lt]=100" : ""
        } else if (this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 2) {
            dilutionValue = this.state.isDilution === "Y" ? "&filters[$and][2][concentration][$gte]=100" : this.state.isDilution === "N" ? "&filters[$and][1][concentration][$lt]=100" : ""
        }
        const categoryValue = this.state.selectedCat != null ? "&filters[$and][1][product][sub_category][middle_category][id][$eq]=" + this.state.selectedCat.toString() : ""

        let api_route = this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 0 ?
            "patch-tests" : this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 1 ?
                "predi-skins" : this.state.selectedStudyType === 0 && this.state.selectedStudyTest === 2 ?
                    "reconstructed-human-epidermiss" : ""

        let productsUrls = [5]
        productsResults.forEach((res, index) => {
            productsUrls[index] = "https://dev-prod-eurosafe.wepredic.com/api/" + api_route + "?filters[result][$eq]=" + res + categoryValue + dilutionValue + occlusionValue
        })

        const values = [0, 0, 0, 0, 0, 0]

        productsUrls.forEach((url, index) => {
            axios.get(url, { headers: { 'Authorization': TOKEN } }).then(response => {


                values[index] = response.data.meta.pagination.total

                this.setState({
                    products: values,
                    edited: true
                })
            })
        })



    }

    dilutionClicked(event) {
        this.setState({
            isDilution: event.target.value
        }, () => this.onEditForm())
    }

    choiceChart() {
        this.setState({
            isPie: !this.state.isPie
        }, () => this.onEditForm())
    }

    occlusifClicked(event) {
        this.setState({
            isOcclusif: event.target.value
        }, () => this.onEditForm())
    }

    onValidForm() {
        this.setState({
            isValid: !this.state.isValid
        })
    }

    changeStudyType(event) {
        this.setState({
            selectedStudyType: parseInt(event.target.value),
            selectedStudyTest: null,
            isDilution: "YN",
            isOcclusif: "YN",
            selectedCat: "",
            products: [],
            edited: false
        })
    }

    changeStudyTest(event) {
        this.setState({
            selectedStudyTest: parseInt(event.target.value),
            isDilution: "YN",
            isOcclusif: "YN",
            selectedCat: "",
            products: [],
            edited: false
        })
    }

    categoryToString(id) {
        const getCatgoryUrl = "https://dev-prod-eurosafe.wepredic.com/api/middle-categories?filters[id][$eq]=" + id

        axios.get(getCatgoryUrl, { headers: { 'Authorization': TOKEN } }).then(response => {

            this.setState({
                categoryTitle: {
                    FR: response.data.data[0].attributes.name,
                    EN: response.data.data[0].attributes.name_en
                }
            })
        })

    }

    render() {

        const ableCategories = [
            "Produits de soin de la peau", 
            "Produits destinés à corriger les odeurs corporelles et/ou la transpiration", 
            "Produits de soins et de nettoyage des cheveux et du cuir chevelu"
           
        ]

        const catOptions = Object.entries(this.props.lang === "FR" ? this.state.categories : this.state.categories_en).map(([category, subCategories]) => (
            <optgroup key={category} label={category}>
                {
                    subCategories.map(subCat => {
                        return <option className={!ableCategories.includes(subCat.attributes.name) ? "text-light-select" : ""} value={subCat.id} disabled={!ableCategories.includes(subCat.attributes.name)} key={subCat.id}>{this.props.lang === "FR" ? subCat.attributes.name : subCat.attributes.name_en}</option>
                    })
                }
            </optgroup>
        ))

        let isAndInTitle = ""
        if (this.state.isDilution !== "YN" && this.state.isOcclusif !== "YN") {
            isAndInTitle = this.props.lang === "FR" ? " et " : " and "
        }
        let dilutionTitle = this.state.isDilution === "Y" ? this.props.lang === "FR" ? "dilués " : "diluted " : this.state.isDilution === "N" ? this.props.lang === "FR" ? "non dilués " : "undiluted " : ""
        let occlusifTitle = this.state.isOcclusif === "Y" ? this.props.lang === "FR" ? "occlusifs " : "occlusives " : this.state.isOcclusif === "N" ? this.props.lang === "FR" ? "semi-occlusifs " : "semi-occlusives " : ""
        let title = this.props.lang === "FR" ? "Potentiel irritant des produits " + dilutionTitle + isAndInTitle + occlusifTitle + "de " + this.state.categoryTitle.FR : "Irritant potential of " + dilutionTitle + isAndInTitle + occlusifTitle + this.state.categoryTitle.EN

        return (


            <Row className='p-xl-5 p-0 m-0 mx-0 mx-xl-5 h-100 '>
                {
                    !this.state.edited ?
                        <Col xl={6} className="bg-form p-2 p-md-5 m-0 h-100 order-xl-1 order-2 "></Col>
                        : this.state.products.reduce((a, b) => a + b, 0) < 5 ?
                            <Col xl={6} className="p-2 p-md-5 bg-theme text-center m-0 position-relative h-100 order-xl-1 order-2" >
                                <div className="position-absolute absolute-center">
                                    {this.props.lang === "FR" ? "Pas assez de produits associés à la sélection" : "Not enough products associated with the selection"}
                                </div>
                            </Col> :
                            <Col id="chart" xl={6} className="p-2 p-md-5 bg-theme text-center m-0 position-relative h-100 order-xl-1 order-2" >
                                <p className="text-white"><u>{title} ({this.state.products.reduce((a, b) => a + b, 0)} {this.props.lang === "FR" ? "produits" : "products"})</u></p>
                                {this.state.isPie ? 
                                    <div className="position-absolute absolute-center pie-width pt-xl-4" >
                                        <Pie
                                            options={options}
                                            data={{
                                                labels: this.props.lang === "FR" ? pieKeys : pieKeysEn,
                                                datasets: [{
                                                    data: this.state.products.map((x) =>
                                                        (x / this.state.products.reduce((val, a) => val + a)) * 100
                                                    ),
                                                    backgroundColor: pieColors,
                                                    hoverOffset: 1,
                                                    borderWidth: 1,
                                                    spacing: 0
                                                }]
                                            }}
                                        />
                                    </div>
                                    
                                        
                                 :
                                <div className="position-absolute absolute-center" style={{ width: "75%" }}>
                                    <Bar
                                        options={barChartOptions}
                                        data={{
                                            labels: this.props.lang === "FR" ? pieKeys : pieKeysEn,
                                            datasets: [{
                                                data: this.state.products.map((x) =>
                                                    (x / this.state.products.reduce((val, a) => val + a)) * 100
                                                ),
                                                backgroundColor: pieColors,
                                                hoverOffset: 1,
                                                borderWidth: 1,
                                                spacing: 0,
                                            }]
                                        }}
                                    />
                                </div>
                                    
                                }

                                <div className="position-absolute absolute-bottom">
                                    <FormGroup className="pt-2">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <span className="small text-white">Histogramme</span>
                                            <AntSwitchPie onChange={this.choiceChart} checked={this.state.isPie} inputProps={{ 'aria-label': 'ant design' }} />
                                            <span className="small text-white">Pie chart</span>
                                        </Stack>
                                    </FormGroup>
                                </div>
                            </Col>
                }

                <Col xl={6} className="bg-theme-light p-2 p-md-4 m-0 position-relative order-xl-2 order-1 h-100">
                    <div className="w-100 text-center"><b>{this.props.lang === "FR" ? "Définissez votre produit" : "Specify your product"}</b></div>

                    {
                        !this.state.isValid ?
                            <Form className="mt-3">
                                <FormGroup>
                                    <div className="small">{this.props.lang === "FR" ? "Sélectionnez le type d'étude associée :" : "Select the associated test type :"}</div>
                                    {
                                        studyType.map((type, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={"radio-button-" + index}
                                                    disabled={index > 0}
                                                    control={<Checkbox key={"checkbox-" + index} value={index} onClick={this.changeStudyType} checked={index === this.state.selectedStudyType ? true : false} size="small" />}
                                                    label={<span className="small text-muted">{this.props.lang === "FR" ? type["FR"] : type["EN"]}</span>}
                                                />
                                            )

                                        })
                                    }
                                </FormGroup>
                                {
                                    this.state.selectedStudyType !== null ?
                                        <>
                                            <FormGroup>
                                                <div className="small">{this.props.lang === "FR" ? "Sélectionnez le type de test associé :" : "Select the associated test type :"}</div>
                                                {
                                                    studyType[this.state.selectedStudyType].tests.map((test, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                key={"radio-button-" + index}
                                                                disabled={test["FR"] == "Patch test"}
                                                                control={<Checkbox key={"checkbox-" + index} value={index} onClick={this.changeStudyTest} checked={index === this.state.selectedStudyTest ? true : false} size="small" />}
                                                                label={<span className="small text-muted">{this.props.lang === "FR" ? test["FR"] : test["EN"]}</span>}
                                                            />
                                                        )

                                                    })
                                                }
                                            </FormGroup>
                                            {
                                                this.state.selectedStudyTest === 0 && this.state.selectedStudyType === 0 ?
                                                    <>
                                                        <FormGroup className="pt-2">
                                                            <div className="small">{this.props.lang === "FR" ? "Sélectionnez la catégorie du produit :" : "Select the product's category :"}</div>
                                                            <select className='form-select w-50 mt-2' onChange={this.onChangeSelectedCategory} defaultValue={""} style={{ borderColor: '#E5904F', backgroundColor: "#fff9f5", fontSize: "12px" }}>
                                                                <option value={""} selected={this.state.selectedCat === ""} key="default">{this.props.lang === "FR" ? "Selectionnez une catégorie" : "Select a category"}</option>
                                                                {catOptions}
                                                            </select>
                                                        </FormGroup>

                                                        <div className="pt-2">
                                                            <div>
                                                                <Label className="small">{this.props.lang === "FR" ? "Le produit est il dilué ? :" : "Is the product diluted"}</Label>
                                                            </div>
                                                            <FormGroup check inline>
                                                                <Input
                                                                    onChange={this.dilutionClicked}
                                                                    name="dilution"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="Y"
                                                                    checked={this.state.isDilution === "Y" ? true : false}
                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Oui" : "Yes"}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Input
                                                                    onChange={this.dilutionClicked}
                                                                    name="dilution"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="N"
                                                                    checked={this.state.isDilution === "N" ? true : false}

                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Non" : "No"}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                inline
                                                                check
                                                            >
                                                                <Input
                                                                    onChange={this.dilutionClicked}
                                                                    name="dilution"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="YN"
                                                                    checked={this.state.isDilution === "YN" ? true : false}

                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Les deux" : "Both"}
                                                                </Label>
                                                            </FormGroup>
                                                        </div>

                                                        <div className="pt-2">
                                                            <div>
                                                                <Label className="small">{this.props.lang === "FR" ? "Type de patch :" : "Patch type :"}</Label>
                                                            </div>

                                                            <FormGroup check inline>
                                                                <Input
                                                                    onChange={this.occlusifClicked}
                                                                    name="occlusive"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="Y"
                                                                    checked={this.state.isOcclusif === "Y" ? true : false}

                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Occlusif" : "Occlusive"}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Input
                                                                    onChange={this.occlusifClicked}
                                                                    name="occlusive"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="N"
                                                                    checked={this.state.isOcclusif === "N" ? true : false}

                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Semi-occlusif" : "Semi-occlusive"}
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup
                                                                inline
                                                                check
                                                            >
                                                                <Input
                                                                    onChange={this.occlusifClicked}
                                                                    name="occlusive"
                                                                    type="radio"
                                                                    className="custom-radio"
                                                                    value="YN"
                                                                    checked={this.state.isOcclusif === "YN" ? true : false}

                                                                />
                                                                {' '}
                                                                <Label check>
                                                                    {this.props.lang === "FR" ? "Les deux" : "Both"}
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </> : this.state.selectedStudyTest === 1 && this.state.selectedStudyType === 0 ?
                                                        <>
                                                            <FormGroup className="pt-2">
                                                                <div className="small">{this.props.lang === "FR" ? "Sélectionnez la catégorie du produit :" : "Select the product's category :"}</div>
                                                                <select className='form-select w-50 mt-2' onChange={this.onChangeSelectedCategory} defaultValue={""} style={{ borderColor: '#E5904F', backgroundColor: "#fff9f5", fontSize: "12px" }}>

                                                                    <option value={""} key="default">{this.props.lang === "FR" ? "Selectionnez une catégorie" : "Select a category"}</option>
                                                                    {catOptions}
                                                                </select>
                                                            </FormGroup>
                                                            <div className="pt-2">
                                                                <div>
                                                                    <Label className="small">{this.props.lang === "FR" ? "Le produit est il dilué ? :" : "Is the product diluted"}</Label>
                                                                </div>
                                                                <FormGroup check inline>
                                                                    <Input
                                                                        onChange={this.dilutionClicked}
                                                                        name="dilution"
                                                                        type="radio"
                                                                        className="custom-radio"
                                                                        value="Y"
                                                                        checked={this.state.isDilution === "Y" ? true : false}
                                                                    />
                                                                    {' '}
                                                                    <Label check>
                                                                        {this.props.lang === "FR" ? "Oui" : "Yes"}
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check inline>
                                                                    <Input
                                                                        onChange={this.dilutionClicked}
                                                                        name="dilution"
                                                                        type="radio"
                                                                        className="custom-radio"
                                                                        value="N"
                                                                        checked={this.state.isDilution === "N" ? true : false}

                                                                    />
                                                                    {' '}
                                                                    <Label check>
                                                                        {this.props.lang === "FR" ? "Non" : "No"}
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup
                                                                    inline
                                                                    check
                                                                >
                                                                    <Input
                                                                        onChange={this.dilutionClicked}
                                                                        name="dilution"
                                                                        type="radio"
                                                                        className="custom-radio"
                                                                        value="YN"
                                                                        checked={this.state.isDilution === "YN" ? true : false}

                                                                    />
                                                                    {' '}
                                                                    <Label check>
                                                                        {this.props.lang === "FR" ? "Les deux" : "Both"}
                                                                    </Label>
                                                                </FormGroup>
                                                            </div>
                                                        </> : this.state.selectedStudyTest === 2 && this.state.selectedStudyType === 0 ?
                                                            <>
                                                                <FormGroup className="pt-2">
                                                                    <div className="small">{this.props.lang === "FR" ? "Sélectionnez la catégorie du produit :" : "Select the product's category :"}</div>
                                                                    <select className='form-select w-50 mt-2' onChange={this.onChangeSelectedCategory} defaultValue={""} style={{ borderColor: '#E5904F', backgroundColor: "#fff9f5", fontSize: "12px" }}>

                                                                        <option value={""} key="default">{this.props.lang === "FR" ? "Selectionnez une catégorie" : "Select a category"}</option>
                                                                        {catOptions}
                                                                    </select>
                                                                </FormGroup>
                                                                <div className="pt-2">
                                                                    <div>
                                                                        <Label className="small">{this.props.lang === "FR" ? "Le produit est il dilué ? :" : "Is the product diluted"}</Label>
                                                                    </div>
                                                                    <FormGroup check inline>
                                                                        <Input
                                                                            onChange={this.dilutionClicked}
                                                                            name="dilution"
                                                                            type="radio"
                                                                            className="custom-radio"
                                                                            value="Y"
                                                                            checked={this.state.isDilution === "Y" ? true : false}
                                                                        />
                                                                        {' '}
                                                                        <Label check>
                                                                            {this.props.lang === "FR" ? "Oui" : "Yes"}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup check inline>
                                                                        <Input
                                                                            onChange={this.dilutionClicked}
                                                                            name="dilution"
                                                                            type="radio"
                                                                            className="custom-radio"
                                                                            value="N"
                                                                            checked={this.state.isDilution === "N" ? true : false}

                                                                        />
                                                                        {' '}
                                                                        <Label check>
                                                                            {this.props.lang === "FR" ? "Non" : "No"}
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        inline
                                                                        check
                                                                    >
                                                                        <Input
                                                                            onChange={this.dilutionClicked}
                                                                            name="dilution"
                                                                            type="radio"
                                                                            className="custom-radio"
                                                                            value="YN"
                                                                            checked={this.state.isDilution === "YN" ? true : false}

                                                                        />
                                                                        {' '}
                                                                        <Label check>
                                                                            {this.props.lang === "FR" ? "Les deux" : "Both"}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                            </> : <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }


                            </Form> :
                            <div className="position-absolute w-100 absolute-center text-center">
                                <p>{this.props.lang === "FR" ? "Merci d'avoir utilisé le SCEP" : "Thank you for using CEP"}</p>
                            </div>
                    }

                    {
                        this.state.selectedCat != null ?
                            <div className='position-absolute hide-on-desktop' style={{ bottom: "1vh", right: "2vw" }}>
                                <a className="button-0 text-dark" href="#chart">{this.props.lang === "FR" ? "Suivant" : "Next"} <FontAwesomeIcon icon={faArrowRightLong} /></a>
                            </div> :
                            <></>
                    }


                </Col>
            </Row>

        )
    }

}


export default AppForm