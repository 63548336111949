import React, { Component } from 'react';
import AppForm from './AppForm';
import GetEmailForm from './GetEmailForm';
import Cookies from 'universal-cookie'


class Home extends Component {
  constructor(props){
    super(props)

    this.state = {
      cookies: {
        displayCookieBanner: false,
        displayCookieModal: false,
        necessary: true,
        performance: false,
        targetting: false
      },
      config: {
        cookieDomain: window.config ? window.config.cookieDomain : "localhost",
      },


    }

  }



  
  render() {
    const cookies = new Cookies()
    const email = cookies.get("email")

    return (
      <div className='full-height p-0 m-0'>        
          {
            email !== undefined ?
            <AppForm lang={this.props.lang}></AppForm> :
            <GetEmailForm config={this.state.config} lang={this.props.lang}></GetEmailForm>    
          }
          {/* <CookieConsent /> */}
    
      </div>
    )
  }
}
export default Home;
