// src/App.js
import React, { Component, useState } from "react"
import './App.css';
import Home from './components/Home';

import poster from './images/Eurosafe_ESTIV_2024.pdf';

import {
  NavItem,
  Navbar,
  NavbarBrand,
  NavLink,
  NavbarToggler,
  Collapse,
  Nav
} from 'reactstrap';

import logo from './images/eurosafe.png'

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      lang: "FR",
      isOpen: false
    };
    this.onChangeLang = this.onChangeLang.bind(this);
    this.toggle = this.toggle.bind(this);

  }


  onChangeLang(event){
    this.setState({
      lang: event.target.value
    })
  }

  toggle(){
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {

    return (
      <div className="App">

        <Navbar className="menu py-0 my-0" expand="md">
          <NavbarBrand href="/" className='py-0'>
            <img alt="logo" src={logo} style={{height: "60px"}} />
          </NavbarBrand>
          
          <Nav>
            <NavItem className="text-dark-blue">
              Tolcheck démo
            </NavItem>
          </Nav>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="w-100 justify-content-end" navbar>
              <NavItem >
                <NavLink className="text-dark-blue" href={poster} download="Eurosafe_ESTIV_2024" target="_blank">{this.state.lang === "FR" ? "Télécharger le poster": "Download the poster"}</NavLink>
              </NavItem>
              <NavItem className="my-auto">
                <select className='form-select text-dark-blue' onChange={this.onChangeLang} defaultValue="FR" style={{ borderColor: '#fff', width: 'fit-content' }}>
                  <option value="FR" key="FR">Français</option>
                  <option value="EN" key="EN">English</option>
                </select>
              </NavItem>
              
              
            </Nav>
          </Collapse>
          
        </Navbar>
        <Home lang={this.state.lang} />
        <div className="position-absolute bottom-center small text-muted">©Eurosafe, 2024.</div>
      </div>
    );
  }
  
}

export default App;
