import axios from 'axios';

import React, { Component } from "react"
import { Button, Input, Col, Row } from 'reactstrap';

import Cookies from "universal-cookie";
const TOKEN = "Bearer ad22e2689143fdc6906b7b12a0c00e7fcb9a587ab5ecf872b9b586c9a251beeacdc1448cc042cb739ff1ef45806d698eaa582c91b203b272f7734dab605b1bdaa712850579cbadfc2d7c8a407bcfcf85f4e7f2013bc86cbeb17c5c2e3e89370ec1f9870588f40aa915797f56be728707652eec1d04f5bc303719941be0359e9c"


const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};


class GetEmailForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: null,
            valid: null,
        };

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.validForm = this.validForm.bind(this);
        this.createMailjetContact = this.createMailjetContact.bind(this);

    }




    async createMailjetContact(email) {

        const url = 'https://dev-prod-eurosafe.wepredic.com/api/prospects'
        try {
            await axios.post(url, 
                {
                    "data": {
                        "email": email,
                        "from": "tolcheck"
                    }
                },
                {
                    headers: { 
                        'Authorization': TOKEN 
                    },
                    
                }
            )
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
        // try {
        //     //localhost:8542
        //     await axios.post('https://tolcheck-express.wepredic.com/api/addContact/', { email, name }).then((response) => {
        //         console.log(response)

        //         const contactId = response.data.ID
        //         const listId = 88128
        //         try {
        //             //localhost:8542
        //             const response2 = axios.post('https://tolcheck-express.wepredic.com/api/addContactToList', { contactId, listId })
        //             console.log(response2.data);
        //             window.location.reload();


        //         } catch (error) {
        //             console.error('There was an error adding the contact into the list!', error);
        //         }
        //     })




        // } catch (error) {
        //     console.error('There was an error getting the contact!', error);
        // }



    }

    validForm(event) {
        event.preventDefault()
        const cookies = new Cookies();
        cookies.set("email", this.state.email, { path: "/" })
        this.createMailjetContact(this.state.email)

    }

    onChangeEmail(event) {

        if (validateEmail(event.target.value)) {
            this.setState({
                email: event.target.value,
                valid: true,
            });
        } else {
            this.setState({
                email: null,
                valid: false,
            });
        }

    }

    render() {



        return (
            <Row className="p-xl-5 p-0 m-0 mx-0 mx-xl-5 h-100 ">
                <Col className="bg-theme-light p-2 p-md-5 m-0 position-relative d-flex justify-content-center">
                    <div className="w-50 position-absolute absolute-center">
                        <h2 className="text-left mb-0" style={{ color: "#E5904F" }}>{this.props.lang === 'FR' ? "Bienvenue sur TolCheck !" : "Welcome on TolCheck"}</h2>
                        <p className='pb-2 mt-0 small text-muted'>{this.props.lang === 'FR' ? "Il s'agit d'une démo, l'accès à certaines informations est bloqué" : "This is the demo, access to certain information is blocked"}</p>
                        <p>{this.props.lang === 'FR' ? "Veuillez renseigner votre adresse email pour accéder à notre application." : "Please enter your e-mail address to access our application."}</p>
                        <Input style={{ borderColor: "#E5904F" }} className="mb-3" type="email" valid={this.state.valid ? true : false} name="email" placeholder={this.props.lang === "FR" ? "Votre adresse email" : "Your email address"} onChange={this.onChangeEmail} />
                        {
                            this.state.valid ?
                                <Button onClick={this.validForm} outline className="mt-3 outline-theme">{this.props.lang === 'FR' ? "Valider" : "Validate"}</Button> : <></>
                        }
                    </div>

                </Col>

            </Row>
        )

    }


}

export default GetEmailForm